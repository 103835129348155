














import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Store from '@/store/Store';
import Chart from 'chart.js';

@Component({})
export default class Gauge extends Vue {
    @Prop()
    width!: string;
    @Prop()
    height!: string;
    @Prop()
    value!: number;
    @Prop({default: '#1997c6'})
    valueColor!: string;
    @Prop({default: '#e6e5e5'})
    negativeValueColor!: string;
    @Prop()
    maxValue!: number;
    @Prop()
    title!: string;
    @Prop()
    centerText!: string;

    chart!: Chart;

    get store(): Store {
        return this.$root.$data.store;
    }

    @Watch('value')
    onValueChanged() {
        this.chart.data.datasets![0].data = [this.value, Math.round((this.maxValue - this.value)*100)/100];

        this.chart.update();
    }

    mounted() {
        let canvas = this.$refs.chart as HTMLCanvasElement;
        this.chart = new Chart(canvas, {
            type: 'doughnut',

            data: {
                datasets: [{
                    label: this.title,
                    backgroundColor: [
                        this.valueColor,
                        this.negativeValueColor
                    ],
                    data: [this.value,  Math.round((this.maxValue - this.value)*100)/100],
                }],
                labels: [
                    this.title,
                    ''
                ]
            },
            // Configuration options go here
            options: {
                animation: {
                    duration: 0 // general animation time
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                },
                hover: {mode: undefined},
                responsive: false,
                cutoutPercentage: 75,
                maintainAspectRatio: true

            },
        });
    }

    public beforeDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
}
